import { createURL as algoliaSearchCreateURL } from './algoliaSearch'
import cacheId from './cacheId'
import chunkArray from './chunkArray'
import { colourBgMap, colourMap } from './colourMap'
import { createCanonicalUrl } from './createCanonicalUrl'
import { createCtaButton } from './createCtaButton'
import { createHeroFloatingCardProps } from './createHeroFloatingCardProps'
import { createHeroImageProps } from './createHeroImageProps'
import { createHeroVideoProps } from './createHeroVideoProps'
import { createHref } from './createHref'
import { createInitStoryModalCustomEvent } from './createInitStoryModalCustomEvent'
import {
  createLevelOfStudyFacets,
  LevelOfStudyFacet
} from './createLevelOfStudyFacets'
import { createUrl } from './createUrl'
import debug from './debug'
import environment, { Environment, isEnv } from './environment'
import { generatePageSeo } from './generatePageSeo'
import GenerateSiteHeadHtml from './GenerateSiteHeadHtml'
import { getUniqueAttributeValuesFromHits } from './getUniqueAttributeValuesFromHits'
import { getYouTubeVideoId } from './getYouTubeVideoId'
import { isExternalUrl } from './isExternalUrl'
import isServer from './isServer'
import {
  getHrefForLocale,
  getLocalisedPath,
  getQueryLocale,
  isHrefExternal
} from './locale'
import { apiLogger, formLogger } from './logs/customLoggers'
import {
  getPageThemeBackgroundColour,
  getPageThemeCtaColour
} from './pageTheme'
import setNZLocation from './setNZLocation'
import { sortAlphabetically } from './sortAlphabetically'
import sortLevelOfSchools from './sortLevelOfSchools'
import unique from './unique'
import { useMediaQuery } from './useMediaQuery'
import { getVideoType } from './getVideoType'
import { removeEscapeCharacters } from './removeEscapeCharacters'

export {
  Environment,
  GenerateSiteHeadHtml,
  apiLogger,
  algoliaSearchCreateURL,
  cacheId,
  chunkArray,
  colourBgMap,
  colourMap,
  createCanonicalUrl,
  createCtaButton,
  createHeroFloatingCardProps,
  createHeroImageProps,
  createHeroVideoProps,
  createHref,
  createInitStoryModalCustomEvent,
  createLevelOfStudyFacets,
  createUrl,
  debug,
  environment,
  formLogger,
  generatePageSeo,
  getPageThemeBackgroundColour,
  getPageThemeCtaColour,
  getUniqueAttributeValuesFromHits,
  getYouTubeVideoId,
  isEnv,
  // GridDebug,
  isServer,
  setNZLocation,
  sortAlphabetically,
  sortLevelOfSchools,
  unique,
  useMediaQuery,
  isExternalUrl,
  getQueryLocale,
  getHrefForLocale,
  isHrefExternal,
  getLocalisedPath,
  getVideoType,
  removeEscapeCharacters
}
export type { LevelOfStudyFacet }
