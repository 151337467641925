import { AspectRatio, Box, ModalCloseButton } from '@chakra-ui/react'
import { CloseIcon } from 'icons'
import { ReactElement, useState } from 'react'
import ReactPlayer from 'react-player/youtube'
import { useTrackVideo } from 'tracking'
import { getVideoType } from 'utils'
import BilibiliPlayer from '../content-components/MediaVideo/BilibiliPlayer'
import Modal from './Modal'

interface VideoModalProps {
  url: string
  isOpen: boolean
  onClose: () => void
}

const VideoModal = ({
  url,
  isOpen,
  onClose
}: VideoModalProps): ReactElement => {
  const [duration, setDuration] = useState(0)
  const [setStartVideoTracking, setEndVideoTracking] = useTrackVideo(
    url,
    duration
  )

  const videoType = getVideoType(url)

  return (
    <Box>
      <Modal isOpen={isOpen} onClose={onClose} size='lg'>
        <Modal.Overlay backgroundColor='rgba(4, 15, 32, 0.8)' />
        <Modal.Content maxWidth='80vw' aspectRatio={16 / 9}>
          <Modal.Body padding={0} overflow='hidden' borderRadius='20px'>
            {videoType == 'bilibili' && (
              <AspectRatio ratio={16 / 9} height='100%'>
                <BilibiliPlayer videoUrl={url} autoplay={isOpen} />
              </AspectRatio>
            )}
            {videoType == 'youtube' && (
              <AspectRatio ratio={16 / 9} height='100%'>
                <ReactPlayer
                  src={url}
                  url={url}
                  key={url}
                  width='100%'
                  playing={isOpen ? true : false}
                  height='100%'
                  controls={true}
                  onDuration={(duration) => setDuration(duration)}
                  onStart={() => setStartVideoTracking(true)}
                  onEnded={() => setEndVideoTracking(true)}
                  data-testid={`video-modal`}
                />
              </AspectRatio>
            )}
          </Modal.Body>
          <ModalCloseButton
            backgroundColor='rgba(255,255,255,0.1)'
            boxSize={{ base: '32px', md: '50px' }}
            top={{ base: '-48px', md: '0' }}
            right={{ base: '0', md: '-70px' }}
            borderRadius='full'
          >
            <CloseIcon boxSize={{ base: 6, md: 8 }} fill='white' />
          </ModalCloseButton>
        </Modal.Content>
      </Modal>
    </Box>
  )
}

export default VideoModal
